.text-whites {
    color: #ffffff;
    /* Light gray text color */
    font-size: 16px;
    /* As desired */
}

.text-whites:before {
    content: "";
    /* Empty content for underline only */
    position: absolute;
    bottom: -5px;
    /* Adjust position as needed */
    left: 0;
    width: 0;
    height: 0.2rem;
    /* Underline thickness */
    background-color: #FFC700;
    /* Underline color */
    transition: width 0.5s ease-in-out;
    /* Smooth animation */
}

.text-whites:hover:before {
    width: 100%;
    /* Expands underline on hover */
}