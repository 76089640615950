.sticky-header {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1111;
}

@media screen and (max-width: 1024px) {
  #contact-us {
    display: none;
  }
}

#top-scroll-bar {
  z-index: 9999;
}


#contact-us:focus {
  outline: none;
}


.custom-link::after,
.dropdown-icon {
  content: none;
  /* Ensure no icon is displayed */
}

.indicator {
  height: 0.2rem;
  background-color: #FFC700;
  transition: all 0.5s ease-in-out;
}

nav ul:hover li {
  border-color: transparent;
}