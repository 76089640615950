@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', sans-serif;
}

.truncate-5-lines {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

html {
  scroll-behavior: smooth;
}

/* .team-section .transform {
  transform: translate(0%, -50%);
} */

.custom-next-arrow {
  margin-left: 25px;
}

.custom-prev-arrow {
  margin-left: -25px;
}

.custom-next-arrow,
.custom-prev-arrow {
  width: 40px !important;
  height: 40px !important;
  background-color: white !important;
  text-align: center !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: 100% !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
}

.custom-next-arrow svg,
.custom-prev-arrow svg {
  width: 24px;
  height: 24px;
}

.custom-next-arrow::before,
.custom-prev-arrow::before {
  display: none;
}

.ourInvestors .slick-arrow.slick-prev {
  right: 70px;
}

.ourInvestors .slick-arrow.slick-next {
  right: 20px;
}

.ourInvestors .slick-arrow.slick-prev,
.ourInvestors .slick-arrow.slick-next {
  left: unset;
  top: unset;
  bottom: -50px;
  position: absolute;

}

.ourInvestors .slick-arrow.slick-next::before,
.ourInvestors .slick-arrow.slick-prev::before {
  display: none;
}

.career-testimonial-slider .slick-arrow.slick-next::before,
.career-testimonial-slider .slick-arrow.slick-prev::before {
  display: none;
}

.ourInvestors .slick-arrow.slick-next svg,
.ourInvestors .slick-arrow.slick-prev svg {
  width: 24px;
  height: 24px;
  color: white;
  background-color: #003fbc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);

}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.animate-bounce {
  animation: bounce 1s infinite;
}

.dotCircle {
  position: relative;
  z-index: 50;
}

.dotCircle:before {
  background: #003fbc;
  -webkit-box-shadow: 0 0 0 .3125rem rgba(42, 135, 247, 0.2);
  box-shadow: 0 0 0 .3125rem rgba(42, 135, 247, 0.2);
  opacity: 0.4;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1);
  transition: transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1);
  transition: transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1), -webkit-transform 0.6s cubic-bezier(0.2, 0.8, 0.4, 1);
}

.dotCircle:after {
  background: #020842;
}

.dotCircle.is-active::before {
  -webkit-transform: scale(2);
  transform: scale(2);

}

.dotCircle:before,
.dotCircle:after {
  content: '';
  position: absolute;
  top: 0;
  width: .5625rem;
  margin-left: -.3125rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.error {
  color: red;
  font-size: 14px;
  font-weight: 400;
}


.no-scroll {
  overflow: hidden;
}

.diagonal-swipe {
  position: relative;
  overflow: hidden;
  display: block;
  text-decoration: none;
  color: inherit;
  transition: color 0.3s, background-color 0.3s;
  box-shadow: none;
  width: fit-content;
}

.diagonal-swipe:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: currentColor;
  z-index: -1;
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.diagonal-swipe:hover strong {
  color: #173CBA;
}


.diagonal-swipe:hover:before {
  transform: translateX(0) translateY(-100%);
}

.solution-contact-form {

  .option-custom {
    font-size: 14px;
    line-height: 24px;
  }

  ::placeholder {
    font-size: 14px;
    line-height: 24px;
    color: #6F6F6F;
  }
}

.CTABtn {
  border-radius: 10rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.CTABtn:hover {
  border-radius: 0;
}

@-moz-document url-prefix() {
  .DotsAndShades {
    width: 100%;
    height: 100%;
    transform: scale(1.4) !important;
    left: -150px;
    top: 40px;
    z-index: -10;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .DotsAndShades {
    width: 100%;
    height: 100%;
    transform: scale(1.4) !important;
    left: -150px;
    top: 36px;
    z-index: -10;
  }
}